import styled from "styled-components";
import { ORANGE_PRIMARY, ORANGE_SECONDARY, TEXT_SECONDARY, WHITE, BORDER, LINK_BULE, RED } from "../../config/Constants";

export const DivPrimaryOrangeButton = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  background-color: ${ORANGE_PRIMARY};
  color: ${WHITE};
  border: 1px solid ${ORANGE_PRIMARY};

  font-size: 1rem;
  line-height: 1rem;
  padding: 0.625rem 1.5rem;
  border-radius: 0.625rem;

  width: 100%;

  &:hover {
    color: ${WHITE};
    opacity: 0.6;
    text-decoration: none;
  }
`

export const DivSecondaryOrangeButton = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  background-color: ${WHITE};;
  color: ${ORANGE_SECONDARY};
  border: 1px solid ${ORANGE_SECONDARY};

  font-size: 1rem;
  line-height: 1rem;
  padding: 0.625rem 1.5rem;
  border-radius: 0.625rem;

  width: 100%;
  max-height: 2rem;

  &:hover {
    color: ${ORANGE_SECONDARY};
    opacity: 0.6;
    text-decoration: none;
  }
`

export const DivSecondaryOrangeButtonPrimaryOrange = styled(DivSecondaryOrangeButton)`
  color: ${ORANGE_PRIMARY};
  border: 1px solid ${ORANGE_PRIMARY};

  &:hover {
    color: ${ORANGE_PRIMARY};
  }

`

export const DivSecondaryDangerButton = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  background-color: ${WHITE};;
  color: ${RED};
  border: 1px solid ${RED};

  font-size: 1rem;
  line-height: 1rem;
  padding: 0.625rem 1.5rem;
  border-radius: 0.625rem;

  width: 100%;
  max-height: 2rem;

  &:hover {
    color: ${RED};
    opacity: 0.6;
    text-decoration: none;
  }

`

export const DivSecondaryOrangeButtonDisable = styled(DivSecondaryOrangeButton)`
  opacity: 0.4;
  color: ${BORDER};
  border: 1px solid ${BORDER};
  &:hover {
    color: ${BORDER};
    opacity: 0.2;
    text-decoration: none;
  }
`

export const DivPrimaryDisableButton = styled(DivPrimaryOrangeButton)`
  opacity: 0.9;
  color: ${WHITE};
  background-color: ${BORDER};
  border: 1px solid ${BORDER};
  &:hover {
    color: ${WHITE};
    opacity: 0.9;
    text-decoration: none;
  }
`

export const APrimaryOrangeButton = styled.a`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  background-color: ${ORANGE_PRIMARY};
  color: ${WHITE};
  border: 1px solid ${ORANGE_PRIMARY};

  font-size: 1rem;
  line-height: 1rem;
  padding: 0.625rem 1.5rem;
  border-radius: 0.625rem;

  width: 100%;
  max-height: 2rem;

  &:hover {
    color: ${WHITE};
    opacity: 0.6;
    text-decoration: none;
  }
`

export const APrimaryOrangeButtonL = styled(APrimaryOrangeButton)`
  font-size: 1.125rem;
  padding: 1rem 1.625rem;
  border-radius: 0.625rem;
`

export const ASecondaryOrangeButton = styled.a`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  background-color: ${WHITE};
  color: ${ORANGE_PRIMARY};
  border: 1px solid ${ORANGE_PRIMARY};

  font-size: 1rem;
  line-height: 1rem;
  padding: 0.625rem 1.5rem;
  border-radius: 0.625rem;

  width: 100%;
  max-height: 2rem;

  &:hover {
    opacity: 0.6;
    text-decoration: none;
    color: ${ORANGE_PRIMARY};
  }
`

export const ASecondaryOrangeButtonL = styled(ASecondaryOrangeButton)`
  font-size: 1.125rem;
  padding: 1rem 1.625rem;
  border-radius: 0.625rem;
`

export const ASecondaryButton = styled.a`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.2s ease;
  text-decoration: none;
  appearance: none;
  background-color: ${WHITE};
  color: ${LINK_BULE};
  border: 1px solid ${LINK_BULE};

  font-size: 1rem;
  line-height: 1rem;
  padding: 0.625rem 1.5rem;
  border-radius: 0.625rem;

  width: 100%;
  max-height: 2rem;

  &:hover {
    opacity: 0.6;
    text-decoration: none;
    color: ${LINK_BULE};
  }
`

export const APremiumButton = styled.a`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
  transition: .4s;
  text-decoration: none;
  appearance: none;
  background-image: linear-gradient(45deg, ${ORANGE_SECONDARY} 0%, #ff8b5f 100%);
  color: ${WHITE};

  font-size: 1rem;
  line-height: 1rem;
  padding: 1.5rem 3rem;
  border-radius: 2rem;

  width: 100%;
  max-height: 2rem;

  &:hover {
    color: ${WHITE};
    opacity: 0.6;
    text-decoration: none;
  }
`
